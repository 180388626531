var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('portal',{attrs:{"to":"body-top"}},[_c('h4',{staticClass:"text-center font-weight-bolder mb-1"},[_vm._v(" "+_vm._s(_vm.$t('View In Active Asset Inventory'))+_vm._s((" — " + (_vm.inventory.sku) + " — " + (_vm.inventory.name)))+" ")])]),(_vm.inventorySerializedAndNonSerializedVal)?_c('l-table',{ref:"lTableRef",attrs:{"is-searchable":false,"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumnsInventoryItems,"create-page-url":{ name: 'home-inventory-item-create' },"is-actions":true,"back-uri":{path: ("/inventory/inventory-view/" + _vm.routeID), query: _vm.paramsQuery()},"table-config-options":_vm.getTableConfigOptions()},scopedSlots:_vm._u([{key:"table-top-left-side",fn:function(){return [_c('tab-change',{attrs:{"table-ref":_vm.$refs,"is-trash-table":true,"push-route":("/inventory/inventory-view/" + _vm.routeID + "/trash-list")}})]},proxy:true},{key:"cell(actions)",fn:function(ref){
var data = ref.data;
return [_c('feather-icon',{staticClass:"cursor-pointer mr-1 border-dotted defaultIconColor",staticStyle:{"padding":"2px 6px"},attrs:{"icon":"LTrashIcon","size":"30"},on:{"click":function($event){return _vm.remove(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"feather-icon border-dotted cursor-pointer defaultIconColor",staticStyle:{"padding":"4px"},attrs:{"title":_vm.$t('Set to active'),"icon":"LLoadIcon","size":"30"},on:{"click":function($event){return _vm.restoreContact(data.item)}}})]}},{key:"cell(warehouse)",fn:function(ref){
var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'warehouse_location.warehouse.label', '—'))+" ")]}},{key:"cell(locations)",fn:function(ref){
var item = ref.data.item;
return [_c('span',{staticClass:"d-block text-center"},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'warehouse_location.display', '—'))+" ")])]}},{key:"cell(sn_last_digits)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.mapSerialNumber(data.item.serial_number))+" ")]}},{key:"cell(asset_id)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.inventorySerializedAndNonSerializedVal ? data.item.id : data.item.amount)+" ")]}},{key:"cell(active_since)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.convertTZ(data.item.created_at))+" ")]}},{key:"cell(current_state)",fn:function(ref){
var item = ref.data.item;
return [_c('state-of-asset',{attrs:{"data":item}})]}}],null,false,66482770)}):_c('l-table',{ref:"lTableRef",attrs:{"is-searchable":false,"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumnsInventoryItemsNonSerialized,"create-page-url":{ name: 'home-inventory-item-create' },"is-actions":true,"trash-list-path":"home-inventory-item-trash-list","table-config-options":_vm.getTableConfigOptions(),"back-uri":{path: ("/inventory/inventory-view/" + _vm.routeID), query: _vm.paramsQuery()}},scopedSlots:_vm._u([{key:"table-top-left-side",fn:function(){return [_c('tab-change',{attrs:{"table-ref":_vm.$refs,"is-trash-table":true,"push-route":("/inventory/inventory-view/" + _vm.routeID + "/trash-list")}})]},proxy:true},{key:"cell(actions)",fn:function(ref){
var data = ref.data;
return [_c('feather-icon',{staticClass:"cursor-pointer mr-1 border-dotted defaultIconColor",staticStyle:{"padding":"2px 6px"},attrs:{"icon":"LTrashIcon","size":"30"},on:{"click":function($event){return _vm.remove(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"feather-icon border-dotted cursor-pointer defaultIconColor",staticStyle:{"padding":"4px"},attrs:{"title":_vm.$t('Set to active'),"icon":"LLoadIcon","size":"30"},on:{"click":function($event){return _vm.restoreContact(data.item)}}})]}},{key:"cell(asset_id)",fn:function(ref){
var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.inventorySerializedAndNonSerializedVal ? _vm.getValueFromGivenObjectByKey(item, 'id', '—') : _vm.getValueFromGivenObjectByKey(item, 'amount', '—'))+" ")]}},{key:"cell(locations)",fn:function(ref){
var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'warehouse_location.display', '—'))+" ")]}},{key:"cell(state)",fn:function(ref){
var item = ref.data.item;
return [_c('state-of-asset',{attrs:{"data":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }