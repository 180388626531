<template>
  <div class="pb-2">
    <portal to="body-top">
      <h4 class="text-center font-weight-bolder mb-1">
        {{ $t('View In Active Asset Inventory') }}{{ ` — ${inventory.sku} — ${inventory.name}` }}
      </h4>
    </portal>
    <l-table
      v-if="inventorySerializedAndNonSerializedVal"
      ref="lTableRef"
      :is-searchable="false"
      :module-name="MODULE_NAME"
      :table-columns="tableColumnsInventoryItems"
      :create-page-url="{ name: 'home-inventory-item-create' }"
      :is-actions="true"
      :back-uri="{path: `/inventory/inventory-view/${routeID}`, query: paramsQuery()}"
      :table-config-options="getTableConfigOptions()"
    >
      <template #table-top-left-side>
        <tab-change
          :table-ref="$refs"
          :is-trash-table="true"
          :push-route="`/inventory/inventory-view/${routeID}/trash-list`"
        />
      </template>
      <template #cell(actions)="{ data }">
        <feather-icon
          icon="LTrashIcon"
          size="30"
          style="padding: 2px 6px"
          class="cursor-pointer mr-1 border-dotted defaultIconColor"
          @click="remove(data.item)"
        />
        <feather-icon
          v-b-tooltip.noninteractive.hover
          :title="$t('Set to active')"
          icon="LLoadIcon"
          size="30"
          style="padding: 4px"
          class="feather-icon border-dotted cursor-pointer defaultIconColor"
          @click="restoreContact(data.item)"
        />
      </template>
      <template #cell(warehouse)="{ data: {item} }">
        {{ getValueFromGivenObjectByKey(item, 'warehouse_location.warehouse.label', '—') }}
      </template>
      <template #cell(locations)="{ data: {item} }">
        <span class="d-block text-center">
          {{ getValueFromGivenObjectByKey(item, 'warehouse_location.display', '—') }}
        </span>
      </template>
      <template #cell(sn_last_digits)="{ data }">
        {{ mapSerialNumber(data.item.serial_number) }}
      </template>
      <template #cell(asset_id)="{ data }">
        {{ inventorySerializedAndNonSerializedVal ? data.item.id : data.item.amount }}
      </template>
      <template #cell(active_since)="{ data }">
        {{ convertTZ(data.item.created_at) }}
      </template>
      <template #cell(current_state)="{ data: { item } }">
        <state-of-asset
          :data="item"
        />
      </template>
    </l-table>
    <l-table
      v-else
      ref="lTableRef"
      :is-searchable="false"
      :module-name="MODULE_NAME"
      :table-columns="tableColumnsInventoryItemsNonSerialized"
      :create-page-url="{ name: 'home-inventory-item-create' }"
      :is-actions="true"
      trash-list-path="home-inventory-item-trash-list"
      :table-config-options="getTableConfigOptions()"
      :back-uri="{path: `/inventory/inventory-view/${routeID}`, query: paramsQuery()}"
    >
      <template #table-top-left-side>
        <tab-change
          :table-ref="$refs"
          :is-trash-table="true"
          :push-route="`/inventory/inventory-view/${routeID}/trash-list`"
        />
      </template>
      <template #cell(actions)="{ data }">
        <feather-icon
          icon="LTrashIcon"
          size="30"
          style="padding: 2px 6px"
          class="cursor-pointer mr-1 border-dotted defaultIconColor"
          @click="remove(data.item)"
        />
        <feather-icon
          v-b-tooltip.noninteractive.hover
          :title="$t('Set to active')"
          icon="LLoadIcon"
          size="30"
          style="padding: 4px"
          class="feather-icon border-dotted cursor-pointer defaultIconColor"
          @click="restoreContact(data.item)"
        />
      </template>
      <template #cell(asset_id)="{ data: { item } }">
        {{ inventorySerializedAndNonSerializedVal ? getValueFromGivenObjectByKey(item, 'id', '—') : getValueFromGivenObjectByKey(item, 'amount', '—') }}
      </template>
      <template #cell(locations)="{ data: { item } }">
        {{ getValueFromGivenObjectByKey(item, 'warehouse_location.display', '—') }}
      </template>
      <template #cell(state)="{ data: { item } }">
        <state-of-asset
          :data="item"
        />
      </template>
    </l-table>
  </div>
</template>

<script>
import LTable from '@/views/components/LTable/LTable.vue'
import config from '@/views/main/inventory/inventoryConfig'
import TabChange from '@/views/main/inventory/inventory-view/components/TabChange.vue'
// eslint-disable-next-line import/no-cycle
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import ListTableActionsNonSerialized
from '@/views/main/inventory/inventory-view/components/ListTableActionsNonSerialized.vue'
import TableActions from '@/views/main/inventory/inventory-view/components/TableActions.vue'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'InventoryTrashList',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    StateOfAsset, TableActions, ListTableActionsNonSerialized, TabChange, LTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      routeID: this.$route.params.id,
    }
  },
  computed: {
    inventory() {
      return this.$store.state[this.MODULE_NAME].product
    },
    inventorySerializedAndNonSerializedVal() {
      return this.$store.state[this.MODULE_NAME].inventorySerializedAndNonSerializedVal
    },
    pathQuery() {
      return this.$route.query
    },
  },
  methods: {
    paramsQuery() {
      const queryString = { ...this.$route.query }
      delete queryString.active
      // return { query: queryString }
      return queryString
    },
    mapSerialNumber(number) {
      if (!number) return '---'

      if (number.length > 4) {
        return `*****${number.slice(-4)}`
      }
      return number
    },
    getTableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/list`,
        data: {
          active: 0,
        },
      }
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/del`)
        .then(
          () => {
            this.refetchData()
          },
        )
    },
    restoreContact(data) {
      this.confirmNotification(this, { ids: [data.id], active: !data.active }, `${this.MODULE_NAME}/statusChange`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const { tableColumnsInventoryItemsNonSerialized, tableColumnsInventoryItems } = config()
    return {
      MODULE_NAME,
      getValueFromGivenObjectByKey,
      tableColumnsInventoryItemsNonSerialized,
      tableColumnsInventoryItems,
    }
  },
}
</script>

<style>

</style>
